var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('e-select',{attrs:{"id":"entity_id","track-by":"tiers_id","label":"tiers_rs","placeholder":_vm.$t('tiers.rechercher_billing_entity'),"selectedLabel":_vm.$t('global.selected_label'),"options":_vm.entity,"searchable":true,"allow-empty":true,"loading":_vm.isLoadingEntity,"show-labels":false,"multiple":true},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.tiers_rs))]}}]),model:{value:(_vm.entity_selected),callback:function ($$v) {_vm.entity_selected=$$v},expression:"entity_selected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('div',{staticClass:"col-12 mt-2"},[_c('DateRangeV2',{attrs:{"start":_vm.start,"end":_vm.end,"dateSelect":"","persistId":"InvoicesChecked","periodToShow":['personalise', 'jour', 'semaine_derniere', 'mois_dernier', 'annee_en_cours', 'annee_derniere']},on:{"update:start":function($event){_vm.start=$event},"update:end":function($event){_vm.end=$event},"submit":_vm.loadInvoicesSupplier}})],1)]),_c('CustomTable',{ref:"invoice_suppliers_ocr_checked",attrs:{"id_table":"invoice_suppliers_ocr_checked","items":_vm.invoices,"busy":_vm.table_busy,"primaryKey":"suppliersocr_id","hide_if_empty":true,"hrefsRoutes":_vm.config_table_hrefs,"externSlotColumns":_vm.extern_slot_columns,"groupByCustom":_vm.customGroupBy},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(suppliersocr_num)",fn:function(ref){
var data = ref.data;
return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.quickPreview(data.suppliersocr_pdf_url)}}},[_vm._v(" "+_vm._s(data.suppliersocr_num)+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'eye']}})],1)]}},{key:"custom-slot-cell(status)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t('invoice.supplier_status.'+data.status))+" ")]}},{key:"custom-slot-cell(suppliersocr_export_status)",fn:function(ref){
var data = ref.data;
return [_c('span',{class:'export_status_'+data.suppliersocr_export_status},[_vm._v(_vm._s(_vm.$t('invoice.supplier_export_status.'+data.suppliersocr_export_status)))])]}},{key:"custom-slot-cell(horses)",fn:function(ref){
var data = ref.data;
return _vm._l((data.horses),function(horse,index){return _c('span',{key:index},[_c('router-link',{attrs:{"to":{ name: 'horseFiche', params: { horse_id: horse.horse_id }}}},[_vm._v(" "+_vm._s(horse.horse_nom)+" ")]),(index != data.horses.length-1)?_c('span',[_vm._v(", ")]):_vm._e()],1)})}},{key:"custom-slot-cell(suppliersocr_duedate_date)",fn:function(ref){
var data = ref.data;
return [_vm._l((data.duedates),function(duedate){return _c('span',{key:duedate.isduedate_id},[_vm._v(" "+_vm._s(_vm.formatDate(duedate.isduedate_date))),_c('br')])}),(data.duedates.length == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(data.suppliersocr_duedate_date))+" ")]):_vm._e()]}}],null,true)}),_c('b-modal',{ref:"modelPreview",attrs:{"size":"xl","hide-footer":""},on:{"hidden":function($event){_vm.pdf_url = null}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("action.previsualiser"))+" ")]},proxy:true}])},[(_vm.pdf_url != null)?_c('iframe',{staticStyle:{"position":"relative"},attrs:{"src":_vm.pdf_url,"height":"1000px","width":"100%"}}):_c('div',[_c('LoadingSpinner')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }